import './style.scss';

// External Deps
import * as React from 'react';
import * as RB from 'react-bootstrap';
import * as RF from 'react-feather';
import { Modal, Button } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { RouterProps, RouteComponentProps } from '@reach/router';
import { connect } from 'react-redux';

// Services
import { LoginToTransfer } from '../../services/session';

// Reducers
import { RootReducerState } from '../reducers';
import { loggedInUser } from '../reducers/user';

// Components
import AppIcon from '../components/AppIcon';

// Utils
import Analytics, { Action } from '../utils/analytics';
import Classname from '../utils/classname';

const initialState = {};

type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ConnectedState &
  RouterProps &
  RouteComponentProps<any> &
  ConnectedActions;

type State = typeof initialState;

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  componentDidMount() {
    Analytics.trackPage(this.props.location);
  }

  /**
   * Handle the login click.
   */
  handleLogin = () => {
    Analytics.trackAction(Action.LoginClick);
    LoginToTransfer();
  };

  buildZapLogoComponent = () => {
    const { currentTask, appsByID } = this.props;
    if (!currentTask) return;

    const importAppID = currentTask?.export_step?.app;
    const exportAppID = currentTask?.import_step?.app;
    const importApp = importAppID ? appsByID[importAppID] : undefined;
    const exportApp = exportAppID ? appsByID[exportAppID] : undefined;

    const classname = Classname({
      'app-logo-container': true,
      'd-flex': true,
      'align-items-center': true,
    });

    return (
      <div className={classname}>
        <AppIcon app={importApp} size={72} />
        <RF.ChevronRight size={32} color={'black'} />
        <AppIcon app={exportApp} size={72} />
      </div>
    );
  };

  buildTransferLogoComponent = () => {
    return (
      <img
        src="https://cdn.zapier.com/storage/photos/3627c3aa31199a4fc0cd3f1977907123_2.png"
        alt="transfer-logo"
        className="transfer-logo"
      />
    );
  };

  buildLogoComponent = () => {
    const { currentTask } = this.props;
    if (currentTask) {
      return this.buildZapLogoComponent();
    }
    return this.buildTransferLogoComponent();
  };

  render() {
    const modalFooter = () => {
      return (
        <div className={'footer-container d-flex justify-content-center'}>
          <Button children={'Log In with Zapier'} type={'primary'} onClick={this.handleLogin} />
        </div>
      )
    }
    return (
      <RB.Container className="login-container">
        <Modal centered className={'login-modal'} visible={true} footer={modalFooter()}>
          <img src={`https://cdn.zapier.com/storage/photos/db0ae9d8cea44691171d3a375db4a497.png`} alt=''></img>

          <div className={'modal-content'}>
            <div className={'modal-row'}>
              <p className='title'>{'What is Transfer?'}</p>
              <p className='desc'>{'Transfer helps you move data in bulk between apps. You can use Transfer to import all of your data from another one app to another.'}</p>
            </div>
            <div className={'modal-row'}>
              <p className='title'>{'How do I use it?'}</p>
              <p className='desc'>{'Simply select the data from your trigger step that you would like to send through your zap, and click the '}<span className="zapier-bold">{'Send to Zap'}</span>{' button.'}</p>
            </div>

          </div>


        </Modal>
      </RB.Container >
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  loggedInUser: loggedInUser(state),
  appsByID: state.app.appsByID,
  currentTask: state.task.currentTask,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
