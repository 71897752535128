// External Deps
import qs from 'querystring';

// Internal Deps
import { Get } from '../common/utils/fetch';
import { TRANSFER_BASE_URL } from '../common/services/transfer';
import { ZAPIER_OAUTH_URL, REDIRECT_URI } from '../common/utils/constants';

import Analytics, { Action } from '../common/utils/analytics';

/**
 * OAUTH
 */

/**
 * Initiate the login OAuth Flow with Zapier
 */
const LoginToTransfer = () => {
  // Need to just redirect the browser
  window.location = ZAPIER_OAUTH_URL as any;
};

/**
 * Initiate the login OAuth Flow with Zapier
 */
const SubmitOauthCode = async (cbQueryString: string) => {
  // Need to just redirect the browser
  const parsed = qs.parse(cbQueryString.replace('?', ''));
  const { code } = parsed;
  if (!code) {
    throw new Error('Invalid query string');
  }
  const completePath = 'oauth/complete';
  const payload = { redirect_url: REDIRECT_URI, code };
  const authReqQs = qs.stringify(payload);
  const url = `${TRANSFER_BASE_URL}${completePath}?${authReqQs}`;
  const resp = await Get(url);
  return resp;
};

/**
 * Logout
 */

/**
 * Eliminate the session on Transfer
 */
const LogoutOfTransfer = (): Promise<void> => {
  return Get('/v1/logout/session').then(() => {
    Analytics.trackAction(Action.LogoutSuccess);
  });
};

export { LoginToTransfer, LogoutOfTransfer, SubmitOauthCode };
