
// Actions
import { Actions, TypeKeys } from '../actions/user';

// Models
import * as models from '../models';
import { RootReducerState } from './index';

// Utils
import Analytics from '../../common/utils/analytics';

export type UserState = typeof defaultState;

const defaultState = {
  me: null as models.User | null,
  refreshingUser: false
};

function user(state = defaultState, action: Actions): UserState {
  switch (action.type) {
    case TypeKeys.FETCHING_ME: {
      return { ...state, refreshingUser: true };
    }
    case TypeKeys.FETCHED_ME: {
      if (action.user) {
        setIdentityForTracking(action.user)
      }

      return { ...state, me: action.user, refreshingUser: false };
    }
    default:
      return state;
  }
}

const setIdentityForTracking = (user: models.User) => {
  if (!user) {
    return Analytics.logUserOut();
  }
  Analytics.identifyUser(user)
}

export const loggedInUser = (state: RootReducerState) => state.user.me;

export default user;
