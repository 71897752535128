// External Dependencies
import { Dispatch, Action, bindActionCreators } from 'redux';
import { setTag, captureEvent } from '@sentry/browser';

// Model
import { isArray, sortBy } from 'underscore';
import {
  Zap,
  Give,
  Node,
  NodeOutput,
  FormattedNodeInput,
  User,
  Action as ZapAction
} from '../models';

// Service
import Transfer from '../services/transfer';
import WSClient, {ReadInvokeConfig, MessageKeys} from '../services/ws';

// Utils
import { rootNodeForZap, finalNodeForZap, actionForNode, validateZapForListing, validateZapForLoad } from '../utils/zap';
import {
  remapAppAndAction,
  remapSelectedAPIWithZapTemplateID,
  remapActionKeyWithZapTemplateID,
  remapActionTypeWithZapTemplateID
} from './utils/zap_template_remapping';

import {RootReducerState} from '../reducers';

import { v4 as uuidv4 } from 'uuid';

const WRITE_DELAY_INCREMENT_IN_MS = 100;

const service = new Transfer();

// Actions
export enum TypeKeys {
  // Resources
  FETCHED_ZAP = 'FETCHED_ZAP',
  FETCHED_ZAPS = 'FETCHED_ZAPS',
  FETCHED_ZAP_USER_METADATA = 'FETCHED_ZAP_USER_METADATA',
  ERROR_FETCHING_ZAP = 'ERROR_FETCHING_ZAP',
  ERROR_FETCHING_ZAPS = 'ERROR_FETCHING_ZAPS',
  FETCHED_RECORDS = 'FETCHED_RECORDS',
  FETCHED_RECORDS_BATCH = 'FETCHED_RECORDS_BATCH', // Incremental variant of `FETCHED_RECORDS`
  FETCHED_GIVES = 'FETCHED_GIVES',
  WROTE_RECORD_TO_NODE = 'WROTE_RECORD_TO_NODE', // Individual
  WROTE_RECORD_TO_ZAP = 'WROTE_RECORD_TO_ZAP', // Individual
  ERROR_WRITING_RECORD_TO_NODE = 'ERROR_WRITING_RECORD_TO_NODE', // Individual
  ERROR_WRITING_RECORD_TO_ZAP = 'ERROR_WRITING_RECORD_TO_ZAP', // Individual
  WROTE_ALL_RECORDS_TO_NODE = 'WROTE_ALL_RECORDS_TO_NODE', // Batch
  WROTE_ALL_RECORDS_TO_ZAP = 'WROTE_ALL_RECORDS_TO_ZAP',
  RESET_WRITE_STATE = 'RESET_WRITE_STATE',

  // State
  FETCHING_ZAP = 'FETCHING_ZAP',
  FETCHING_ZAPS = 'FETCHING_ZAPS',
  FETCHING_RECORDS = 'FETCHING_RECORDS',
  FETCHING_GIVES = 'FETCHING_GIVES',
  WRITING_RECORDS_TO_NODE = 'WRITING_RECORDS_TO_NODE',
  WRITING_RECORDS_TO_ZAP = 'WRITING_RECORDS_TO_ZAP',
  RESET_ZAP_STATE = 'RESET_ZAP_STATE',
}

export type Actions =
  | FetchingZapAction
  | FetchingZapsAction
  | FetchingZapRecordsAction
  | FetchingZapGivesAction
  | FetchedZapAction
  | FetchedZapsAction
  | FetchedZapUserMetadata
  | FetchedZapRecordsAction
  | FetchedBatchOfZapRecordsAction
  | FetchedZapGivesAction
  | WritingRecordsToNode
  | WritingRecordsToNode
  | WritingRecordsToZap
  | WroteRecordToNode
  | WroteRecordToZap
  | WroteAllRecordsToNode
  | WroteAllRecordsToZap
  | FailedToFetchZapAction
  | FailedToFetchZapsAction
  | ErrorWritingRecordToNode
  | ErrorWritingRecordToZap
  | ResetWriteState
  | ResetZapState;

// -----------------------------------------------------------------------------
// Fetch Zaps
// -----------------------------------------------------------------------------

export interface FetchedZapsAction extends Action {
  type: TypeKeys.FETCHED_ZAPS;
  zaps: Zap[];
}

export interface FailedToFetchZapsAction extends Action {
  type: TypeKeys.ERROR_FETCHING_ZAPS;
  error: Error;
}

export interface FetchingZapsAction extends Action {
  type: TypeKeys.FETCHING_ZAPS;
}

const fetchZaps = (accountID?: number) => async (
  dispatch: Dispatch<
    FetchedZapsAction | FetchingZapsAction | FailedToFetchZapsAction
  >
) => {
  dispatch({ type: TypeKeys.FETCHING_ZAPS });
  try {
    const zaps = await service.fetchZaps(accountID);
    const filteredZaps = zaps.filter(validateZapForListing);
    const sortedZaps = sortBy(filteredZaps, 'lastchanged');
    dispatch({ type: TypeKeys.FETCHED_ZAPS, zaps: sortedZaps });
  } catch (e) {
    if (e.detail) {
      return dispatch({ type: TypeKeys.ERROR_FETCHING_ZAPS, error: (e as any).detail });
    }
    throw e;
  }
};

// -----------------------------------------------------------------------------
// Fetch Zap
// -----------------------------------------------------------------------------

export interface FetchedZapAction extends Action {
  type: TypeKeys.FETCHED_ZAP;
  zap: Zap;
}

export interface FailedToFetchZapAction extends Action {
  type: TypeKeys.ERROR_FETCHING_ZAP;
  error: Error;
  externalID?: number; // If the user needs to fix a zap
}

export interface FetchingZapAction extends Action {
  type: TypeKeys.FETCHING_ZAP;
  zapID: number
}

const fetchZap = (zapID: number, templateID?: number) => async (
  dispatch: Dispatch<
    FetchedZapAction | FetchingZapAction | FailedToFetchZapAction | FetchedZapUserMetadata
  >
) => {
  setTag('zapID', `${zapID}`);
  dispatch({ type: TypeKeys.FETCHING_ZAP, zapID });
  try {
    const zap = await service.fetchZap(zapID);
    let zapErrorMsg = validateZapForLoad(zap);

    const rootAuthID = rootNodeForZap(zap)?.authentication_id;
    if (!zapErrorMsg && rootAuthID) {
      // We need to test the root node's auth
      if (templateID) {
        fetchTemplateUserMetadata(zap, dispatch);
      }

      try {
        await service.validateAuth(rootAuthID);
      } catch (e) {
        zapErrorMsg = `Your connection to ${rootNodeForZap(zap)?.implementation.name} appears to no longer be working.`;
      }
    }

    if (zapErrorMsg) {
      const err = new Error(zapErrorMsg);
      return dispatch({ type: TypeKeys.ERROR_FETCHING_ZAP, error: err, externalID: zap.id });
    }

    dispatch({ type: TypeKeys.FETCHED_ZAP, zap });
  } catch (e) {
    if ((e as any).detail) {
      const err = new Error((e as any).detail);
      return dispatch({ type: TypeKeys.ERROR_FETCHING_ZAP, error: err });
    }
    throw e;
  }
};

// -----------------------------------------------------------------------------
// Fetch Metadata For User From App
// -----------------------------------------------------------------------------

export interface FetchedZapUserMetadata extends Action {
  type: TypeKeys.FETCHED_ZAP_USER_METADATA;
  metadata: Map<string, any>
}

/**
 * Fetches metadata about the user who arrived here from another app. Not super
 * generalized right now...
 */
const fetchTemplateUserMetadata = async (zap: Zap, dispatch: Dispatch<FetchedZapUserMetadata>) => {
  const node = finalNodeForZap(zap);
  for (const action of node?.implementation.actions || []) {
    if (action.type === 'search' && action.key === 'metaData') {
      const metadata = await service.searchRecords(action.key, node!.implementation.selected_api, {}, node!.authentication_id);
      dispatch({type: TypeKeys.FETCHED_ZAP_USER_METADATA, metadata: metadata[0]});
    }
  }
};

// -----------------------------------------------------------------------------
// Fetch Zap Records
// -----------------------------------------------------------------------------

export interface FetchingZapRecordsAction extends Action {
  type: TypeKeys.FETCHING_RECORDS;
}

export interface FetchedZapRecordsAction extends Action {
  type: TypeKeys.FETCHED_RECORDS;
  records?: NodeOutput[];
  nodeID: number;
}

export interface FetchedBatchOfZapRecordsAction extends Action {
  type: TypeKeys.FETCHED_RECORDS_BATCH;
  records: NodeOutput[];
  nodeID: number;
}

export interface FetchingZapGivesAction extends Action {
  type: TypeKeys.FETCHING_GIVES;
}

export interface FetchedZapGivesAction extends Action {
  type: TypeKeys.FETCHED_GIVES;
  nodeID: number;
  gives: Give[];
}

const fetchZapReadRecords = (zap: Zap, ztId: number | null) => async (
  dispatch: Dispatch<
    | FetchingZapRecordsAction
    | FetchingZapGivesAction
    | FetchedBatchOfZapRecordsAction
    | FetchedZapRecordsAction
    | FetchedZapGivesAction
  >, getState: () => RootReducerState
) => {
  const rootNode = rootNodeForZap(zap);
  if (!rootNode) {
    throw Error('Unable to resolve root node for zap');
  }

  const rootAction = actionForNode(rootNode);
  if (!rootAction) {
    throw Error('Unable to resolve root action for zap');
  }

  dispatch({ type: TypeKeys.FETCHING_RECORDS });
  dispatch({ type: TypeKeys.FETCHING_GIVES });

  const remappedAppAndAction = remapAppAndAction({action: rootAction, selected_api: rootNode.implementation.selected_api});
  let selectedAPI = remappedAppAndAction.selected_api;

  // We're (optionally) overriding the selectedAPI for the read if we have a matching
  // source ZapTemplateID

  if (ztId) {
    selectedAPI = remapSelectedAPIWithZapTemplateID(ztId) || selectedAPI;
    remappedAppAndAction.action.key = remapActionKeyWithZapTemplateID(ztId) || remappedAppAndAction.action.key;
    remappedAppAndAction.action.type = remapActionTypeWithZapTemplateID(ztId) || remappedAppAndAction.action.type;
  }

  const config: ReadInvokeConfig = {
    action: remappedAppAndAction.action.key,
    selectedAPI,
    params: rootNode.params,
    typeOf: remappedAppAndAction.action.type,
    authId: rootNode.authentication_id
  };

  const onMessage = (message: any) => {
    console.log('On Message ', message.type);
    if (message.type === MessageKeys.BATCH_READ) {
      const records: any[] = message.results || [];
      const nodeOutputs = records.map((record: any) => {
        return new NodeOutput(record, rootNode.id);
      });

      // Check for first Record batch, dispatch gives fetch if so
      if (!Object.keys(getState().zap.zapRecordsByRootRecordID).length) {
        bindActionCreators(fetchZapGives, dispatch)(records[0], remappedAppAndAction.action, rootNode);
      }
      console.log('Dispatching record batch');
      dispatch({
        type: TypeKeys.FETCHED_RECORDS_BATCH,
        records: nodeOutputs,
        nodeID: rootNode.id,
      });
    } else if (message.type === MessageKeys.END_READ) {
      dispatch({
        type: TypeKeys.FETCHED_RECORDS,
        nodeID: rootNode.id,
      });
    }

  };

  const onError = (message: Event) => {
    captureEvent({
      event_id: 'WS Error',
      extra: message,
      tags: {
        userID: `${user.id}`,
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        zapID: `${zap.id}`
      }
    });
    console.log('[ZapActions] - onError', message);
    dispatch({
      type: TypeKeys.FETCHED_RECORDS,
      nodeID: rootNode.id,
    });
  };

  const onClose = (message: CloseEvent) => {
    dispatch({
      type: TypeKeys.FETCHED_RECORDS,
      nodeID: rootNode.id,
    });

    console.log('[ZapActions] - onClose', message);
  };

  const user = getState().user.me!;
  const client = new WSClient(user, config, onMessage, onClose, onError);
  client.connect();
};

const fetchZapGives = (resultSample: any, rootAction: ZapAction, rootNode: Node) => async (
  dispatch: Dispatch<
    | FetchingZapGivesAction
    | FetchedZapGivesAction
  >
) => {
  dispatch({type: TypeKeys.FETCHING_GIVES});
  const givesLookup = service
    .getGivesForAction(
      rootAction.key,
      rootAction.type,
      rootNode.selected_api,
      rootNode.params,
      rootNode.authentication_id,
      resultSample
    )
    .then((gives: Give[]) => {
      dispatch({
        type: TypeKeys.FETCHED_GIVES,
        gives,
        nodeID: rootNode.id,
      });
    });

  await givesLookup;

};

// -----------------------------------------------------------------------------
// Write Zap Records
// -----------------------------------------------------------------------------

export interface WritingRecordsToNode extends Action {
  type: TypeKeys.WRITING_RECORDS_TO_NODE;
  nodeID: number;
  records: FormattedNodeInput[];
}

export interface WroteRecordToNode extends Action {
  type: TypeKeys.WROTE_RECORD_TO_NODE;
  input: FormattedNodeInput;
  output: NodeOutput;
}

export interface ErrorWritingRecordToNode extends Action {
  type: TypeKeys.ERROR_WRITING_RECORD_TO_NODE;
  input: FormattedNodeInput;
  error: Error;
}

export interface WroteAllRecordsToNode extends Action {
  type: TypeKeys.WROTE_ALL_RECORDS_TO_NODE;
  nodeID: number;
  results: NodeOutput[];
}

const writeRecordsToNode = (
  records: FormattedNodeInput[],
  node: Node
) => async (
  dispatch: Dispatch<
    | WritingRecordsToNode
    | WroteRecordToNode
    | WroteAllRecordsToNode
    | FetchingZapGivesAction
    | FetchedZapGivesAction
    | ErrorWritingRecordToNode
  >
) => {
  dispatch({
    type: TypeKeys.WRITING_RECORDS_TO_NODE,
    records,
    nodeID: node.id,
  });
  dispatch({ type: TypeKeys.FETCHING_GIVES });
  const deferredWrites: Array<Promise<NodeOutput>> = [];

  for (let i = 0; i < records.length; i++) {
    const record = records[i];
    const payloadToWrite = record.data;

    const deferred = new Promise<NodeOutput>(
      (resolve: (node: NodeOutput) => void) => {
        const writeOp = () =>
          service
            .writeRecords(
              node.action,
              node.selected_api,
              payloadToWrite,
              node.authentication_id
            )
            .then((response: any) => {
              if (isArray(response) && response.length) {
                response = response[0];
              }
              const output = new NodeOutput(
                response,
                node.id,
                record.previousNodeOutput
              );
              dispatch({
                type: TypeKeys.WROTE_RECORD_TO_NODE,
                input: record,
                output,
              });
              return output;
            })
            .then(resolve)
            .catch((err: Error) => {
              dispatch({
                type: TypeKeys.ERROR_WRITING_RECORD_TO_NODE,
                input: record,
                error: err,
              });
            });

        setTimeout(writeOp, i * WRITE_DELAY_INCREMENT_IN_MS);
      }
    );
    deferredWrites.push(deferred);
  }

  const writeAction = actionForNode(node);
  if (!writeAction) {
    throw Error('Unable to resolve root action for zap');
  }

  const givesLookup = service
    .getGivesForAction(
      writeAction.key,
      writeAction.type,
      node.selected_api,
      node.params,
      node.authentication_id
    )
    .then((gives: Give[]) => {
      dispatch({
        type: TypeKeys.FETCHED_GIVES,
        gives,
        nodeID: node.id,
      });
    });

  const newRecords = await Promise.all(deferredWrites);
  await givesLookup; // Should resolve prior to the above deferral on writes

  dispatch({
    type: TypeKeys.WROTE_ALL_RECORDS_TO_NODE,
    results: newRecords,
    nodeID: node.id,
  });
};

export interface WritingRecordsToZap extends Action {
  type: TypeKeys.WRITING_RECORDS_TO_ZAP;
  nodeID: number;
  records: NodeOutput[];
}

export interface WroteRecordToZap extends Action {
  type: TypeKeys.WROTE_RECORD_TO_ZAP;
  input: NodeOutput;
  output: any;
}

export interface WroteAllRecordsToZap extends Action {
  type: TypeKeys.WROTE_ALL_RECORDS_TO_ZAP;
  nodeID: number;
}
export interface ErrorWritingRecordToZap extends Action {
  type: TypeKeys.ERROR_WRITING_RECORD_TO_ZAP;
  record: NodeOutput;
  error: Error;
}

const writeRecordsToZap = (
  records: NodeOutput[],
  user: User,
  node: Node
) => async (
  dispatch: Dispatch<
    | WritingRecordsToZap
    | WroteRecordToZap
    | WroteAllRecordsToZap
    | ErrorWritingRecordToZap
  >
) => {
  dispatch({
    type: TypeKeys.WRITING_RECORDS_TO_ZAP,
    records,
    nodeID: node.id,
  });
  const deferredWrites: Array<Promise<any>> = [];

  const batch_id = uuidv4();

  for (let i = 0; i < records.length; i++) {
    const record = records[i];
    const payloadToWrite = record.data;
    const deferred = new Promise<any>((resolve: (node: any) => void) => {
      const writeOp = () =>
        service
          .writeRecordsToZap(
            user.zapier_customuser_id,
            node,
            payloadToWrite,
            batch_id,
          )
          .then((response: any) => {
            dispatch({
              type: TypeKeys.WROTE_RECORD_TO_ZAP,
              input: record,
              output: response,
            });
            return response;
          })
          .then(resolve)
          .catch((err: Error) => {
            // Lets retry once
            dispatch({
              type: TypeKeys.ERROR_WRITING_RECORD_TO_ZAP,
              record,
              error: err,
            });
          });

      setTimeout(writeOp, i * WRITE_DELAY_INCREMENT_IN_MS);
    });
    deferredWrites.push(deferred);
  }

  await Promise.all(deferredWrites);

  dispatch({
    type: TypeKeys.WROTE_ALL_RECORDS_TO_ZAP,
    nodeID: node.id,
  });
};

// -----------------------------------------------------------------------------
// Reset Write State
// -----------------------------------------------------------------------------

export interface ResetWriteState extends Action {
  type: TypeKeys.RESET_WRITE_STATE;
}

const resetWriteState = () => async (dispatch: Dispatch<ResetWriteState>) => {
  dispatch({ type: TypeKeys.RESET_WRITE_STATE });
};


// -----------------------------------------------------------------------------
// Reset Zap State
// -----------------------------------------------------------------------------

export interface ResetZapState extends Action {
  type: TypeKeys.RESET_ZAP_STATE;
}

const resetZapState = () => async (dispatch: Dispatch<ResetZapState>) => {
  dispatch({ type: TypeKeys.RESET_ZAP_STATE });
};

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

export const ZapActions = {
  fetchZap,
  fetchZaps,
  fetchZapReadRecords,
  writeRecordsToNode,
  writeRecordsToZap,
  resetWriteState,
  resetZapState,
};
