import {captureEvent} from '@sentry/browser';
import {User} from '../models/user';
import {TRANSFER_HOST, TRANSFER_V1_CONSUMER_PATH} from './transfer'



type onMessageHandler = (message: JSON, connection: WebSocket) => void;
type onCloseHandler = (message: CloseEvent) => void;
type onErrorHandler = (message: Event) => void;

export interface ReadInvokeConfig {
    action: string,
    selectedAPI: string,
    params: any,
    typeOf: string,
    authId?: number | undefined
}

// Message Keys
export const MessageKeys = {
    BATCH_READ : 'BATCH_READ',
    END_READ : 'END_READ',
};

export default class WSClient {
    private client: WebSocket;

    private config: ReadInvokeConfig;

    private user: User;

    private onMessageHandler: onMessageHandler;

    private onCloseHandler: onCloseHandler;

    private onErrorHandler: onErrorHandler;

    constructor(user: User, config: ReadInvokeConfig, onMessage: onMessageHandler, onCloseHandler: onCloseHandler, onErrorHandler: onErrorHandler) {
        this.config = config;
        this.user = user;
        this.onMessageHandler = onMessage;
        this.onCloseHandler = onCloseHandler;
        this.onErrorHandler = onErrorHandler;
    }

    public connect = () => {
        if (!this.client) {
            const userAccessToken = this.user.transfer_api_token;
            // Passing this token because there's an issues w Django expecting a cookie....
            // Kinda annoying - https://github.com/django/channels/issues/510#issuecomment-288677354
            const loc = window.location;
            let protocol = 'ws:';
            let host = 'localhost:9056';
            if (loc.protocol === 'https:') {
                protocol = 'wss:';
                host = TRANSFER_HOST;
            }

            const WSURL = `${protocol}//${host}/${TRANSFER_V1_CONSUMER_PATH}?token=${userAccessToken}`;
            this.client = new WebSocket(WSURL);

            const that = this;
            this.client.onopen = function(this: WebSocket, ev: Event) {
                console.log('[WS] - open event: ', ev);
                this.send(JSON.stringify({'read_config': that.config}));
            };

            this.client.onmessage = function(this: WebSocket, ev: MessageEvent) {
                if (typeof(ev.data) === 'string') {
                    try {
                        const payload = JSON.parse(ev.data);
                        that.onMessageHandler(payload, this);
                    } catch (e) {
                        console.log('[WS.Connect] - Unable to parse JSON message: ', e, ev.data);
                        captureEvent({ extra: { error: e, data: ev.data }, message: 'Unable to parse JSON message' });
                    }
                }
            };

            this.client.onclose = function(this: WebSocket, ev: CloseEvent) {
                console.log('[WS] - This is the on close: ', ev);
                that.onCloseHandler(ev);
            };

            this.client.onerror = function(this: WebSocket, ev: Event) {
                console.log('[WS] - This is the on error: ', ev);
                that.onErrorHandler(ev);
            };
        }
    };

    public close = () => {
        if (this.client) {
            this.client.close();
        }
    };
}
